<template>
<div v-if="testimonialList.length>0">
  <v-list  v-for="(testimonialEnity,index) in testimonialList" :key="index" color="#ECF0F3">
 <v-row>
 <v-col cols="12">  
  <v-card flat>
    <v-row>
      <v-col cols="8">
        <!-- <div class="text-h7 font-weight-bold mx-2 my-3">
          {{testimonialEnity.customerPointOFContact}}
        </div> -->
      </v-col>
      <v-col cols="3">
        <!-- <div class="text-h7 font-weight-bold">
          <v-checkbox
            label="Verified Checkbox"
            color="indigo"
            value="indigo"
            hide-details
          ></v-checkbox>
        </div> -->
      </v-col>
      <v-col cols="1">
        <div class="text-h7 font-weight-bold mx-2 my-3">
         <EditTestimonial :testimonialID="testimonialEnity.id"/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n10 primary--text">
          Customer point of contact
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n10 text--primary">
            {{testimonialEnity.customerPointOFContact}}
        </div>
      </v-col>
    </v-row>
     <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n6 primary--text">
          Poc designation
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3  my-n6 text--primary">
            {{testimonialEnity.desigination}}
        </div>
      </v-col>
    </v-row>
     <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n2 primary--text">
          Customer name
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n2  text--primary">
            {{testimonialEnity.customerName}}
        </div>
      </v-col>
    </v-row>
     <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1 primary--text">
          Reviewed on 
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n1  text--primary">
            {{testimonialEnity.testimonialYear}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1 primary--text">
          Role of poc
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n1  text--primary">
            {{testimonialEnity.roleOfPoc}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1 primary--text">
          Comepetance/Skill Considered
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n1  text--primary">
            {{testimonialEnity.competenceSkill}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1 primary--text">
          Testimonial description
        </div>
      </v-col>
       <v-col cols="9">
        <div class="text-h7 font-weight-bold mx-3 my-n1  text--primary">
            {{testimonialEnity.testimonialText}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1 primary--text">
          Source of testimonial
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-3 my-n1  text--primary">
            {{testimonialEnity.sourceOfTestimonial}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="text-h7 font-weight-bold mx-3 my-n1 primary--text">
          Testimonial attachment
        </div>
      </v-col>
       <v-col cols="6">
        <div class="text-h7 font-weight-bold mx-n15 my-n4  text--primary">
            <DownloadFile fileType="TESTIMONIAL_UPLOAD" fileLabel="" :fileUrl="testimonialEnity.testimonialAttachementUrl" :index="index"/>
        </div>
      </v-col>
    </v-row>
  </v-card>
   </v-col> 
</v-row>
  </v-list>
</div>
  <!-- <v-card v-else>
    <h1>No Testimonial associated with trainer</h1>
  </v-card> -->
</template>

<script>
//import Pill from '../../components/ChipComponent/pill.vue'
import EditTestimonial from '../../components/TestimonailDialog/testimonialDailog.vue'
import DownloadFile from '../DownloadFile.vue'
import { mapState } from "vuex";

export default {
  name: "ReferenceCard",
  components:{
    //Pill,
    EditTestimonial,
    DownloadFile
  },
data(){
  return{
  } 
},
computed: {
    ...mapState("trainerProfile", {
      loading: "loading",
      profile: "profile",
      testimonialList: "testimonialList"
    })
  },
props:['trainerId'],
created() {
     this.$store.dispatch('trainerProfile/getAllTestimonials',this.trainerId)
  },
};
</script>

<style></style>
<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="1200px">
      <template v-slot:activator="{ on }">
        <v-icon v-if="testimonialID" color="primary" v-on="on"   @click="getTestimonial">
          border_color
        </v-icon>
        <v-icon color="primary" v-on="on" large v-else>
          add
        </v-icon>
      </template>
      <v-card>
        <v-row>
          <v-col cols="10">
            <v-card-title>
          <span class="text-h5 font-weight-bold" v-if="!testimonialID">
            Add a new testimonial</span
          >
          <span class="text-h5 font-weight-bold mx-3" v-else>
            Edit Testimonial</span
          >
        </v-card-title>
          </v-col>
          <v-col cols="1">
             <v-icon color="red" @click="closeForm" dark large dense>
              cancel
            </v-icon>
          </v-col>
          <v-col cols="1">
            <v-icon
              color="green"
              @click="createTestmonial"
              v-if="!testimonialID"
              dark
              large
              dense
            >
              done
            </v-icon>
            <v-icon color="blue" v-else @click="updateTestimnonal" dark large dense>
              done
            </v-icon>
          </v-col>
        </v-row>
        
        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="valid" ref="createTestimonialFrom">
            <v-container>
              <v-row v-if="skillNames.length>0">
                <div class="text-h6 font-weight-bold">
                  Competance /Skill Considered <span class="red--text">*</span>
                </div>
              </v-row>
              <v-row v-if="skillNames.length>0">
                <v-select
                  placeholder="Type here . . . . "
                  outlined
                  solo
                  :items="skillNames"
                  v-model="testimonial.competenceSkill"
                ></v-select>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Testimonial Text
                </div>
              </v-row>
              <v-row>
                <v-textarea
                  outlined
                  placeholder="Type here..."
                  v-model="testimonial.testimonialText"
                >
                </v-textarea>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Testimonial Date
                </div>
              </v-row>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="testimonial.testimonialYear"
                    label=""
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    placeholder="Select Date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="testimonial.testimonialYear"
                  @input="menu = false"
                  :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Customer Name
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  outlined
                  required
                  placeholder="Type here . . . . "
                  v-model="testimonial.customerName"
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  POC Name
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  outlined
                  required
                  placeholder="Type here . . . . "
                  v-model="testimonial.customerPointOFContact"
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Designation of the POC
                </div>
              </v-row>
              <v-row>
                <v-text-field
                  outlined
                  required
                  placeholder="Type here . . . . "
                  v-model="testimonial.desigination"
                ></v-text-field>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Role Of POC
                </div>
              </v-row>
              <v-row>
                <v-select
                  :items="rolesPoc"
                  label=""
                  required
                  outlined
                  placeholder="Select role of poc"
                  v-model="testimonial.roleOfPoc"
                ></v-select>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Source of Testimonial
                </div>
              </v-row>
              <v-row>
                <v-select
                  :items="sourceOfTestimonial"
                  label=""
                  required
                  placeholder="Select source of testimonial"
                  outlined
                  v-model="testimonial.sourceOfTestimonial"
                ></v-select>
              </v-row>
              <v-row>
                <div class="text-h6 font-weight-bold mx-2">
                  Email/ Doc Attachement
                </div>
              </v-row>
              <v-row>
                <UploadDoc type="TESTIMONIAL_UPLOAD" />
              </v-row>
              <v-row class="my-n7">
              <DownloadFile fileLabel="" :fileUrl="testimonial.testimonialAttachementUrl" fileType="TESTIMONIAL_UPLOAD"/>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import UploadDoc from "@/components/uploadDoc.vue";
import DownloadFile from "@/components/DownloadFile.vue"
export default {
  name: "Testiomonial",
  data: () => ({
    dialog: false,
    valid: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    todayDate: new Date(),
    menu: false,
    skillNames:[],
    sourceOfTestimonial: ["LinkedIn", "Personal Website","Customer","OT Customer"],
    rolesPoc: ["Business manager", "L&D Stream", "Paricipant"]
  }),
  components: {
    UploadDoc,
    DownloadFile
  },
async  created() {
   let skillOfTrainer =await this.$store.dispatch('trainerProfile/getSkillNames', this.profile.trainerId)
   console.log('skill names inside ',skillOfTrainer );
   if(skillOfTrainer.length>0){
     this.skillNames=skillOfTrainer
   }
  },
  computed: {
    ...mapState("trainerProfile", {
      testimonial: "testimonial",
      loading: "loading",
      profile: "profile"
    })
  },
  props:["testimonialID"],
  methods: {
    cb() {
      this.dialog = false;
      this.$refs.createTestimonialFrom.reset()
    },
    currentMonth() {
      let today = new Date();
      let currentYear =
        today.getFullYear() + "-" + Number(today.getMonth() + 1);
      console.log("current year", currentYear);
      return currentYear;
    },
    closeForm(){
      this.dialog=false,
      this.$refs.createTestimonialFrom.reset()
    },
    createTestmonial() {
      if(this.testimonial.testimonialYear==null){
        console.log('testimonial year is null');
      }
      //if (this.$refs.createTestimonialFrom.valid) {
        // console.log("new Testimonial   ----", this.testimonial);
        let upadateRefernce = this.testimonial;
        upadateRefernce.trainerId = this.profile.trainerId;
        // upadateRefernce.testimonialYear=new Date().toISOString()
        // console.log("updated testimonial   ---", upadateRefernce);
        this.$store.dispatch("trainerProfile/createTesimonial", {
          testimonial: this.testimonial,
          cb: this.cb
        });
      //}
    },
    getTestimonial(){
        // console.log('testimonal idd ' ,this.testimonialID);
            this.$store.dispatch('trainerProfile/getTestimonialToEdit',this.testimonialID)
      },
      updateTestimnonal(){
      //   console.log(' update the testiimonial ');
      //  console.log('updated testimonial-------- ' ,this.editTestimonial);
        this.$store.dispatch('trainerProfile/updateTestimonial',{
          cb:this.cb,
          testimonial:this.testimonial
        })
    },
  }
};
</script>

<style></style>

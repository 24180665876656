<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col col="11">
        <div class="text-h5 font-weight-bold mx-3">
        Testimonials
        </div>
      </v-col>
      <v-col  cols="1">
    <!-- dailog to add  refrences -->
    <TestimonialDailog/>
      </v-col>
    </v-row>
    <TestimonialCard :trainerId="profile.trainerId"/>
    <div :class="true?activeClass:nonActive">
             Admin comments
    </div>
    <AdminComments :showAddButton="true" type="TESTIMONIALS"/>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import SecondaryNavigation from "@/components/SecondaryNavigation/SecondaryNavigation.vue";
import AdminComments from "../../../components/AdminComments/AdminComments.vue";
import TestimonialDailog from '@/components/TestimonailDialog/testimonialDailog.vue'
import TestimonialCard from '@/components/TestimonialCard/testimonialcard.vue'
export default {
  name: "Testimonial",
  computed:{
      ...mapState('trainerProfile',{
          loading:'loading',
          profile:'profile',
          referencesList:'referencesList'
      })
      },
      data() {
        return {
           activeClass:"text-h6 font-weight-bold mx-3",
      nonActive:"text-h6 primary--text font-weight-bold",
        }
      },
  components: {
   AdminComments,
    SecondaryNavigation,
    TestimonialDailog,
    TestimonialCard
  }
};
</script>

<style></style>
